<template>
  <base-section
    id="before-footer"
    style="background-color: #e3e3e3;"
    class="py-11 pb-5"
  >
      <v-container>
        <v-row>
          <v-col
            cols="4"
          >
            <v-img
              :src="require(`@/assets/howellcare_logo_new_footer.png`)"
              max-width="200px"
            ></v-img>
          </v-col>
          <v-col
            cols="6"
          >
            <v-row>
              <v-img
                :src="require(`@/assets/linkedin_logo.png`)"
                max-height="50px"
                max-width="50px"
                contain
                @click=fnOpenLinkedIn
              ></v-img>
              <v-img
                :src="require(`@/assets/whatsapp_logo.png`)"
                max-height="50px"
                max-width="50px"
                contain
                @click=fnOpenWhatsapp
              ></v-img>
              <v-img
                v-if="wechatClicked"
                :src="require(`@/assets/wechat_qr.jpg`)"
                max-height="150px"
                max-width="150px"
                contain
                @click=fnOpenWechat
              ></v-img>
              <v-img
                v-else
                :src="require(`@/assets/wechat_logo.png`)"
                max-height="50px"
                max-width="50px"
                contain
                @click=fnOpenWechat
              ></v-img>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="8"
            sm="4"
          >
            <a
              href="/"
              style="color:black;"
            >HOME</a><br>
            <a
              href="/products"
              style="color:black;"
            >PRODUCTS</a><br>
            <a
              href="/about"
              style="color:black;"
            >ABOUT US</a><br>
            <a
              href="/core"
              style="color:black;"
            >CORE BUSINESS</a><br>
            <a
              href="/csr"
              style="color:black;"
            >CSR</a><br>
            <a
              href="/contact"
              style="color:black;"
            >CONTACT US</a>
          </v-col>
          <v-col
            cols="8"
            sm="4"
          >
            <h4>ADDRESS</h4>
            <p>
              Lot 833, Jalan Mustaffa Al-Bakri,
              <br>36000 Teluk Intan,
              <br>Perak Darul Ridzuan, Malaysia.
            </p>
          </v-col>
          <v-col
            cols="8"
            sm="4"
          >
            <h4>PHONE</h4>
            <p>
              (+60) 5-621-3187
            </p>
            <h4>EMAIL</h4>
            <p>
              inquiry@howellcareindustries.com<br>
              marketing@howellcareindustries.com
            </p>
          </v-col>
        </v-row>
      </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionBeforeFooter',

    data: () => {
      return {
        wechatClicked: false,
        stats: [
          'DESIGN',
          'ENGINEERING',
          'TECHNOLOGY',
          'INNOVATION',
        ],
      }
    },
    methods: {
      fnOpenLinkedIn () {
        window.open('https://www.linkedin.com/in/howellcare-industries-471868288', '_blank')
      },
      fnOpenWechat () {
        this.wechatClicked = !this.wechatClicked
      },
      fnOpenWhatsapp () {
        window.open('https://api.whatsapp.com/send/?phone=601161138993', '_blank')
      },
    },
  }
</script>
